const characters = "0123456789ABCDEF";

export default function generateRandomHex(length) {
  let hex = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    hex += characters[randomIndex];
  }

  return hex;
}
