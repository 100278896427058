import { useEffect } from "react";
import { useSelector } from "react-redux";

function changeTheme(selectedTheme) {
  const bodyElement = document.getElementsByTagName("body")[0];
  bodyElement.setAttribute("data-bs-theme", selectedTheme);
}

export function useChangeTheme() {
  const theme = useSelector((state) => state.theme.theme);

  useEffect(() => {
    changeTheme(theme);
  }, [theme]);

  return null;
}
