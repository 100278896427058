import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./authSlice";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import themeReducer from "./themeSlice";

const themePersistConfig = {
  key: "theme",
  storage,
};

const themePersistedReducer = persistReducer(themePersistConfig, themeReducer);

const store = configureStore({
  reducer: {
    auth: authReducer,
    theme: themePersistedReducer,
    devTools:
      process.env.REACT_APP_ENV !== "production"
        ? { enhancer: composeWithDevTools() }
        : false,
    middleware: [thunk],
  },
});

const persistor = persistStore(store);

export { store, persistor };
