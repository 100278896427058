import Api from "./axios.util";
import ERROR_CODES from "../data/errorCodes.json";
import { v4 as uuidv4 } from 'uuid';

export const deleteItem = async ({
  e,
  id,
  api,
  items,
  setItems,
  isDeleting,
  setIsDeleting,
  setIsDeletingSuccess,
  setIsDeletingErr,
  setCurrentItem,
}) => {
  e?.preventDefault();
  if (isDeleting) return;

  setIsDeleting(true);
  setIsDeletingSuccess(false);
  setIsDeletingErr(false);

  try {
    await api.delete({
      id,
    });

    const filteredItems = items.filter((item) => item._id !== id);

    setItems(filteredItems);
    setIsDeletingSuccess(true);
    setCurrentItem(null);
  } catch (error) {
    console.log(error);
    setIsDeletingErr(ERROR_CODES[error.response.status]);
  } finally {
    setIsDeleting(false);
  }
};

export const addOrUpdateItem = async ({
  e,
  itemData,
  currentItem,
  setCurrentItem,
  setItems,
  setIsSending,
  setIsError,
  setIsSuccess,
  apiEndPoint1,
  apiEndPoint2,
  resetFields,
  additionalFormData = [],
  contentType,
}) => {
  e?.preventDefault();
  setIsSending(true);
  setIsError(false);
  setIsSuccess(false);

  try {
    let res;
    const apiEndpoint = currentItem ? apiEndPoint1 : apiEndPoint2;
    const api = new Api(apiEndpoint, contentType);
    const FD = new FormData();
    for (const key in itemData) {
      if (
        typeof itemData[key] === "object" ||
        (Array.isArray(itemData[key]) && itemData[key] !== null)
      ) {
        FD.append(key, JSON.stringify(itemData[key]));
      } else {
        FD.append(key, itemData[key]);
      }
    }
    if (additionalFormData.length > 0)
      additionalFormData.forEach((element) => {
        FD.append("files", element);
      });

    if (currentItem) {
      itemData.id = currentItem._id;

      res = await api.patch(FD);
      const { item } = res.data;
      setCurrentItem(item);
      setItems((prevItems) =>
        prevItems.map((item) =>
          item._id === res.data.item._id ? res.data.item : item
        )
      );
    } else {
      res = await api.post(FD);
      const { item } = res.data;
      setItems((prevItems) => [...prevItems, res.data.item]);
      setCurrentItem(item);
    }
    if (res) {
      setIsSuccess(true);

      if (resetFields) resetFields();
    }
  } catch (error) {
    console.log(error);
    setIsError(ERROR_CODES[error.response.status]);
  } finally {
    setIsSending(false);
  }
};


export const addProductItem = async ({
  e,
  itemData,
  setCurrentItem,
  setItems,
  setIsSending,
  setIsError,
  setIsSuccess,
  apiEndPoint1,
  resetFields,
  contentType,
}) => {
  e?.preventDefault();
  setIsSending(true);
  setIsError(false);
  setIsSuccess(false);

  try {
    let res;
    const apiEndpoint = apiEndPoint1;
    const api = new Api(apiEndpoint, contentType);
    const FD = new FormData();
    FD.append('formTogetherProduct', JSON.stringify(itemData.formTogetherProduct));
    FD.append('categories', JSON.stringify(itemData.formCategories));
    FD.append('prices', JSON.stringify(itemData.prices));
    FD.append('product_name', itemData.productName);
    FD.append('product_name_en', itemData.productNameEn);
    FD.append('product_description', itemData.productDescription);
    FD.append('kokuprofili', itemData.kokuProfili);
    FD.append('tepeustnota', itemData.tepeUstNota);
    FD.append('ortanota', itemData.ortaNota);
    FD.append('dipnota', itemData.dipNota);
    FD.append('stock_level', itemData.stockLevel);
    FD.append('free_shipping', itemData.freeShipping);
    FD.append('featured_product', itemData.featuredProduct);
    FD.append('allow_purchases', itemData.allowPurchases);
    FD.append('product_visible', itemData.isVisible);
    FD.append('page_title', itemData.pageTitle);
    FD.append('meta_keywords', itemData.metaKeywords);
    FD.append('meta_description', itemData.metaDescription);
    FD.append('page_url', itemData.page_url);
    FD.append('page_url_en', itemData.page_url_en);


    var imageArray = []

    itemData.images.forEach((item, index2) => {
      var obj = {
        image_name: item.value_name,
      }
      var arrımage = []

      const newFiles = Array.from(item.files).map((file, index) => {
        var imgPath = ".png"

        if (file.type == "image/png") {
          imgPath = ".png"

        } else if (file.type == "image/webp") {
          imgPath = ".webp"
        } else if (file.type == "image/jpeg" || file.type == "image/jpg") {
          imgPath = ".jpeg"
        } else if (file.type == "image/svg+xml") {
          imgPath = ".svg"
        }
        const newName = uuidv4() + "-" + itemData.page_url + "-" + index2 + `${index}` + imgPath; // Yeni dosya adı oluşturma
        const obj = {
          image_url: newName
        }
        arrımage.push(obj)
        return new File([file], newName, { type: file.type }); // Yeni dosyayı oluşturma
      });
      newFiles.map((key2) => {
        FD.append('covers', key2);
      })
      obj.image = arrımage
      imageArray.push(obj)

    })


    FD.append('images', JSON.stringify(imageArray));

    res = await api.post(FD);
    const { item } = res.data;
    setItems((prevItems) => [...prevItems, res.data.item]);
    setCurrentItem(item);
    if (res) {
      setIsSuccess(true);

      if (resetFields) resetFields();
    }
  } catch (error) {
    console.log(error);
    setIsError(ERROR_CODES[error.response.status]);
  } finally {
    setIsSending(false);
  }
};

export const updateProductItem = async ({
  e,
  itemData,
  setCurrentItem,
  setItems,
  setIsSending,
  setIsError,
  setIsSuccess,
  apiEndPoint1,
  resetFields,
  contentType,
}) => {
  e?.preventDefault();
  setIsSending(true);
  setIsError(false);
  setIsSuccess(false);

  try {
    let res;
    const apiEndpoint = apiEndPoint1;
    const api = new Api(apiEndpoint, contentType);
    const FD = new FormData();
    FD.append('product_id', itemData.productId);
    FD.append('deleteImageArr', JSON.stringify(itemData.deleteImageArr));
    FD.append('formTogetherProduct', JSON.stringify(itemData.formTogetherProduct));
    FD.append('categories', JSON.stringify(itemData.formCategories));
    FD.append('prices', JSON.stringify(itemData.prices));
    FD.append('product_name', itemData.productName);
    FD.append('product_name_en', itemData.productNameEn);
    FD.append('product_description', itemData.productDescription);
    FD.append('kokuprofili', itemData.kokuProfili);
    FD.append('tepeustnota', itemData.tepeUstNota);
    FD.append('ortanota', itemData.ortaNota);
    FD.append('dipnota', itemData.dipNota);
    FD.append('stock_level', itemData.stockLevel);
    FD.append('free_shipping', itemData.freeShipping);
    FD.append('featured_product', itemData.featuredProduct);
    FD.append('allow_purchases', itemData.allowPurchases);
    FD.append('product_visible', itemData.isVisible);
    FD.append('page_title', itemData.pageTitle);
    FD.append('meta_keywords', itemData.metaKeywords);
    FD.append('meta_description', itemData.metaDescription);
    FD.append('page_url', itemData.page_url);
    FD.append('page_url_en', itemData.page_url_en);


    var imageArray = []

    itemData.images.forEach((item, index2) => {
      var obj = {
        image_name: item.value_name,
      }
      var arrımage = []

      const newFiles = Array.from(item.files).map((file, index) => {
        var imgPath = ".png"

        if (file.type == "image/png") {
          imgPath = ".png"

        } else if (file.type == "image/webp") {
          imgPath = ".webp"
        } else if (file.type == "image/jpeg" || file.type == "image/jpg") {
          imgPath = ".jpeg"
        } else if (file.type == "image/svg+xml") {
          imgPath = ".svg"
        }
        const newName = uuidv4() + "-" + itemData.page_url + "-" + index2 + `${index}` + imgPath; // Yeni dosya adı oluşturma
        const obj = {
          image_url: newName
        }
        arrımage.push(obj)
        return new File([file], newName, { type: file.type }); // Yeni dosyayı oluşturma
      });
      newFiles.map((key2) => {
        FD.append('covers', key2);
      })
      obj.image = arrımage
      imageArray.push(obj)

    })


    FD.append('images', JSON.stringify(imageArray));

    res = await api.post(FD);
    const { item } = res.data;
    setItems((prevItems) => [...prevItems, res.data.item]);
    setCurrentItem(item);
    if (res) {
      setIsSuccess(true);

      if (resetFields) resetFields();
    }
  } catch (error) {
    console.log(error);
    setIsError(ERROR_CODES[error.response.status]);
  } finally {
    setIsSending(false);
  }
};


export const postCouponItem = async ({
  e,
  itemData,
  setCurrentItem,
  setItems,
  setIsSending,
  setIsError,
  setIsSuccess,
  apiEndPoint1,
  resetFields,
  contentType,
}) => {
  e?.preventDefault();
  setIsSending(true);
  setIsError(false);
  setIsSuccess(false);

  try {
    let res;
    const apiEndpoint = apiEndPoint1;
    const api = new Api(apiEndpoint, contentType);
    const FD = new FormData();
    FD.append('products', JSON.stringify(itemData.filteredProducts));
    FD.append('users', JSON.stringify(itemData.filteredUsers));
    FD.append('coupon_name', itemData.coupon_name);
    FD.append('coupon_code', itemData.coupon_code);
    FD.append('coupon_device', itemData.coupon_device);
    FD.append('coupon_description', itemData.coupon_description);
    FD.append('start_date', itemData.start_date);
    FD.append('end_date', itemData.end_date);
    FD.append('coupon_value', itemData.coupon_value);
    FD.append('coupon_type', itemData.coupon_type);
    FD.append('coupon_limit', itemData.coupon_limit);
    FD.append('coupon_usage', itemData.coupon_usage);
    FD.append('coupon_disposable', itemData.coupon_disposable);
    FD.append('coupon_visible', itemData.coupon_visible);
    if (itemData.coupon_image) {
      FD.append('coupon_image', itemData.coupon_image);
    }
    if (itemData.coupon_id) {
      FD.append('coupon_id', itemData.coupon_id);

    }
    res = await api.post(FD);
    const { item } = res.data;
    setItems((prevItems) => [...prevItems, res.data.item]);
    setCurrentItem(item);
    if (res) {
      setIsSuccess(true);

      if (resetFields) resetFields();
    }
  } catch (error) {
    console.log(error);
    setIsError(ERROR_CODES[error.response.status]);
  } finally {
    setIsSending(false);
  }
};