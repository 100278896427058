const IMG = {
  maxSize: 1,
  fixedHeight: 704,
  fixedWidth: 704,
  supportedFormats: ["image/png"],
  smallMessageType: "Görsel formatı png olmalıdır.",
  smallMessageSize: "Görsel boyutu maksimum 1 MB olabilir.",
  smallMessageResolution: "Görsel 704x704 çözünürlüğünde olmalıdır.",
  selectImageText: "Görsel yüklemek için tıklayınız / görseli sürükleyiniz.",
  warnings: [
    "Görsel türü png formatında olmalıdır.",
    "Görsel boyutu maksimum 1 MB olabilir.",
    "Görsel 704x704 çözünürlüğünde olmalıdır.",
  ],
};
const IMGCOUPON = {
  maxSize: 1,
  supportedFormats: ["image/png","image/jpg"],
  smallMessageType: "Görsel formatı png veya jpg olmalıdır.",
  smallMessageSize: "Görsel boyutu maksimum 1 MB olabilir.",
  selectImageText: "Görsel yüklemek için tıklayınız / görseli sürükleyiniz.",
  warnings: [
    "Görsel türü png veya jpg formatında olmalıdır.",
    "Görsel boyutu maksimum 1 MB olabilir.",
  ],
};

const REFERANCE_IMG = {
  maxSize: 1,
  fixedHeight: 400,
  fixedWidth: 600,
  supportedFormats: ["image/png"],
  smallMessageType: "Görsel formatı png olmalıdır.",
  smallMessageSize: "Görsel boyutu maksimum 1 MB olabilir.",
  smallMessageResolution: "Görsel 600x400 çözünürlüğünde olmalıdır.",
  selectImageText: "Görsel yüklemek için tıklayınız / görseli sürükleyiniz.",
  warnings: [
    "Görsel türü png formatında olmalıdır.",
    "Görsel boyutu maksimum 1 MB olabilir.",
    "Görsel 600x400 çözünürlüğünde olmalıdır.",
  ],
};

const ZIP = {
  maxSize: 15,
  supportedFormats: ["application/zip"],
  smallMessageType: "Dosya formatı zip olmalıdır.",
  smallMessageSize: "Dosya boyutu maksimum 15 MB olabilir.",
  selectImageText: "Dosya yüklemek için tıklayınız / dosyayı sürükleyiniz.",
  warnings: [
    "Dosya formatı zip olmalıdır.",
    "Dosya boyutu maksimum 15 MB olabilir.",
  ],
};

export { IMG, REFERANCE_IMG, ZIP,IMGCOUPON };
