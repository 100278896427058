import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Progress,
} from "reactstrap";
import InputField from "../../InputField";
import Spinner from "../../Spinner";
import Api from "../../../utils/axios.util";
import { passwordValidation } from "../../../helpers";

const ResetPasswordForm = () => {
  const formRef = useRef();
  const theme = useSelector((state) => state.theme.theme);
  const user = useSelector((state) => state.auth.user);
  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [newPasswordAgainError, setNewPasswordAgainError] = useState(false);

  const [isSending, setIsSending] = useState(false);
  const [isError, setIsError] = useState("");
  const [isSuccess, setIsSuccess] = useState("");

  const [resetCode, setresetCode] = useState();

  const [countdown, setCountdown] = useState(240);

  const [isResetCodeSending, setIsResetCodeSending] = useState(false);
  const [isResetCodeErr, setIsResetCodeErr] = useState(false);

  useEffect(() => {
    if ((step === 2 || step === 3) && countdown > 0) {
      const timer = setInterval(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [countdown, step]);

  useEffect(() => {
    if (countdown === 0) {
      setStep(4);
    }
  }, [countdown]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setCode(value);
  };
  // STEP 0NE
  const resetCodeSender = async (e) => {
    e.preventDefault();
    const api = new Api("user/change-password-code");

    if (isResetCodeSending) return;
    setIsResetCodeSending(true);
    setIsResetCodeErr(false);
    try {
      const res = await api.post({
        email: user.email,
      });
      setresetCode(res.data.changePasswordCode);
      setStep(2);
    } catch (error) {
      setIsResetCodeErr(true);
      console.log(error);
    } finally {
      setIsResetCodeSending(false);
    }
  };
  // STEP TWO
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const prepareCodes = (e) => {
    setIsInvalidCode(false);
    e.preventDefault();
    if (resetCode == code) nextStep();
    else setIsInvalidCode(true);
  };

  // STEP THREE
  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    const api = new Api("user/change-password");

    if (isSending) return;
    setIsSending(true);
    setIsError(false);
    setIsSuccess(false);
    try {
      await api.post({
        email: user.email,
        newPassword,
      });
      setIsSuccess(true);
    } catch (error) {
      setIsError(true);
      console.log(error);
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    if (newPassword !== newPasswordAgain) setNewPasswordAgainError(true);
    else setNewPasswordAgainError(false);
  }, [newPassword, newPasswordAgain]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <p>{user.email} adresine bir onay kodu gönderilecektir.</p>

            {isResetCodeSending ? (
              <Spinner className="dark" />
            ) : (
              <Button
                color={theme === "dark" ? "primary" : "purple"}
                onClick={resetCodeSender}
                size="sm"
              >
                Kod Gönder
              </Button>
            )}
            {isResetCodeErr && (
              <div className="text-danger mt-2">İşlem gerçekleştirilemedi.</div>
            )}
          </div>
        );
      case 2:
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              nextStep();
            }}
          >
            <Progress value={countdown} max={240} color="purple">
              {countdown} saniye
            </Progress>

            <FormGroup className="mt-2">
              <Label for="code">Onay Kodu</Label>
              <Input
                type="text"
                name="code"
                id="code"
                placeholder="E-mail adresinize gelen onay kodunu giriniz..."
                value={code}
                onChange={handleChange}
              />
            </FormGroup>
            <Button
              color={theme === "dark" ? "primary" : "purple"}
              size="sm"
              onClick={prepareCodes}
            >
              Kodu Doğrula
            </Button>

            {isInvalidCode && (
              <div className="text-danger mt-2">Hatalı bir kod girdiniz.</div>
            )}
          </form>
        );
      case 3:
        return (
          <form onSubmit={resetPasswordHandler} ref={formRef}>
            <Progress
              value={countdown}
              max={240}
              color="purple"
              className="mb-3"
            >
              {countdown} saniye
            </Progress>

            <FormGroup>
              <InputField
                value={newPassword}
                setValue={setNewPassword}
                label="Yeni Şifreniz"
                name="newPassword"
                id="newPassword"
                setError={setNewPasswordError}
                error={newPasswordError}
                placeholder="Lütfen yeni şifrenizi giriniz..."
                type="password"
                args={{
                  autoComplete: "new-password",
                }}
                validationSchema={passwordValidation(
                  "newPassword",
                  true,
                  6,
                  10
                )}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="newPasswordAgain">Yeni Şifreniz Tekrar</Label>
              <Input
                type="password"
                name="newPasswordAgain"
                id="newPasswordAgain"
                placeholder="Lütfen yeni şifrenizi tekrar giriniz..."
                invalid={newPasswordAgainError}
                onChange={(e) => setNewPasswordAgain(e.target.value)}
                valid={
                  !(newPasswordAgainError && newPasswordError) && newPassword
                }
              />
              <FormFeedback invalid>Şifreleriniz eşleşmiyor.</FormFeedback>
            </FormGroup>

            {isSending ? (
              <Spinner className="dark" />
            ) : isSuccess ? (
              <div className="text-success mt-2">İşlem gerçekleştirildi.</div>
            ) : (
              <Button
                color={theme === "dark" ? "primary" : "purple"}
                size="sm"
                type="submit"
                onSubmit={resetPasswordHandler}
                disabled={newPasswordAgainError || !newPassword}
              >
                Şifreyi Yenile
              </Button>
            )}
            {isError && (
              <div className="text-danger mt-2">İşlem gerçekleştirilemedi.</div>
            )}
          </form>
        );
      case 4:
        return (
          <div>
            <p className="text-warning">İşlem zaman aşımına uğradı.</p>
          </div>
        );
      default:
        return null;
    }
  };

  return <div>{renderStep()}</div>;
};

export default ResetPasswordForm;
