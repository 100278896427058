import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import Api from "../utils/axios.util";
import { decryptText } from "../helpers";
import { login } from "../redux/authSlice";

const useAuth = () => {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const user = decryptText(Cookies.get(process.env.REACT_APP_USER_KEY));
    dispatch(login(user));
    if (location.pathname === "/") {
      return;
    }

    const checkToken = async () => {
      const api = new Api("xDdJsdEv-admin/check-token");
      setIsLoading(true);
      const token = Cookies.get(process.env.REACT_APP_TOKEN_LOCALHOST);

      if (!token) {
        navigate("/");
        return;
      }

      try {
        await api.get();
        setTimeout(() => {
          setIsLoading(false);
        }, 750);
      } catch (error) {
        setTimeout(() => {
          setIsLoading(false);
        }, 750);
        console.log(error);
        navigate("/");
      }
    };

    const refreshToken = async () => {
      const refreshApi = new Api("xDdJsdEv-admin/refresh-token");
      try {
        const res = await refreshApi.post({ email: user.email });
        if (res.status === 200) return;
        else {
          Cookies.set(process.env.REACT_APP_TOKEN_LOCALHOST, res.data.token);
        }
      } catch (error) {
        console.error(error);
        navigate("/");
      }
    };

    checkToken();

    const refreshInterval = setInterval(() => {
      refreshToken();
    }, 15 * 60 * 1000);

    return () => clearInterval(refreshInterval);
  }, [location.pathname]);

  return { isLoading };
};

export default useAuth;
