import { useEffect, useMemo, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { Button, CardBody, Input, InputGroup } from "reactstrap";
import parse from "html-react-parser";
import MailCard from "./MailCard";
import SaveCancelButton from "../SaveCancelButton";
import ModalApprovemation from "../ModalApprovemation";
import InputField from "../InputField";
import DragAndDrop from "../DragAndDrop";
import { addOrUpdateItem, deleteItem } from "../../utils/crud.util";
import {
  emailValidation,
  requiredValidation,
  formatFullDate,
} from "../../helpers";
import MailPrevMessage from "./MailPrevMessage";
import Drawer from "../Drawer";

import Api from "../../utils/axios.util";
import { APP_BASE_URL } from "../../data/env";
import ERROR_CODES from "../../data/errorCodes.json";
import { ZIP } from "../../data/Img";
import {
  ADD,
  DELETE,
  DOWNLOAD,
  FLAG_FILLED,
  INBOX,
  SEND,
} from "../../data/icons";

const CATEGORIES = {
  "technical-support": "Teknik Yardım Merkezi",
  feedback: "Geri Dönüş",
  career: "Kariyer",
  "contact-us": "İletişim",
  "get-a-quotation": "Teklif Al",
  "join-now": "Şimdi Katıl",
};

const SIDE_BAR_TABS = [
  {
    status: "inbox",
    text: "Gelen Kutusu",
    icon: INBOX,
  },
  {
    status: "sent",
    text: "Gönderilen",
    icon: SEND,
  },
  {
    status: "important",
    text: "Önemli",
    icon: FLAG_FILLED,
  },

  {
    status: "deleted",
    text: "Silinenler",
    icon: DELETE,
  },
];

function MailCompose({
  setItems,
  currentItem,
  isCompose = true,
  setCurrentItem = () => {},
  endpoint,
}) {
  const [isSending, setIsSending] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [to, setTo] = useState("");
  const [toErr, setToErr] = useState("");

  const [subject, setSubject] = useState("");
  const [subjectErr, setSubjectErr] = useState("");

  const [message, setMessage] = useState("<p><br></p>");
  const [textErr, setTextErr] = useState("");

  const [file, setFile] = useState();
  const [dragDropError, setDragDropError] = useState("");

  useEffect(() => {
    if (currentItem) {
      setTo(currentItem.from);
      setSubject(currentItem.subject);
    } else {
      resetFields();
    }
  }, [currentItem]);

  // MODAL
  const [alertModal, setAlertModal] = useState(false);

  const onClickHandler = () => {
    if (
      isSending ||
      !to ||
      toErr ||
      !subject ||
      subjectErr ||
      message === "<p><br></p>" ||
      textErr ||
      dragDropError
    ) {
      setIsError(true);
    } else {
      setIsError(false);
      setAlertModal(true);
    }
  };

  const resetFields = () => {
    setTo("");
    setSubject("");
    setFile("");
    setMessage("<p><br></p>");
  };

  const handleSubmit = async (e) => {
    const itemData = {
      category: endpoint,
      name: "",
      surname: "",
      from: "PerfumeAtelier@gmail.com",
      to,
      subject,
      message,
    };

    if (currentItem) {
      itemData._id = currentItem._id;
      itemData.property = "reply";
    }

    await addOrUpdateItem({
      e,
      itemData,
      currentItem,
      setCurrentItem,
      setItems,
      setIsSending,
      setIsError,
      setIsSuccess,
      resetFields,
      apiEndPoint1: `${endpoint}/mails`,
      apiEndPoint2: `${endpoint}/mails/create`,
      additionalFormData: typeof file !== String && [file],
      contentType: "multipart/form-data",
    });
  };

  return (
    <div className="px-1">
      <div className="h5">Yeni E-mail Oluştur</div>
      {isCompose && (
        <>
          <InputField
            className="mt-3"
            id="to"
            value={to}
            setValue={setTo}
            placeholder="Kime:"
            error={toErr}
            setError={setToErr}
            validationSchema={emailValidation("to")}
          />
          <InputField
            className="mt-3"
            id="subject"
            value={subject}
            setValue={setSubject}
            placeholder="Konu:"
            error={subjectErr}
            setError={setSubjectErr}
            validationSchema={requiredValidation("subject", 2, 60)}
          />
        </>
      )}
      <InputField
        className="mt-3"
        id="message"
        value={message}
        setValue={setMessage}
        placeholder="Metin..."
        error={textErr}
        setError={setTextErr}
        validationSchema={requiredValidation("message", 2, 60)}
        type="text-editor"
      />
      <DragAndDrop
        {...ZIP}
        file={file}
        setFile={setFile}
        errorMessage={dragDropError}
        setErrorMessage={setDragDropError}
      />
      <SaveCancelButton
        isSending={isSending}
        isSuccess={isSuccess}
        isError={isError}
        onSave={onClickHandler}
        successText="Gönder"
      />

      <ModalApprovemation
        modal={Boolean(alertModal)}
        toggle={() => setAlertModal(!alertModal)}
        title={`Mail Oluştur / Yanıtla`}
        handleSubmit={handleSubmit}
        isSending={isSending}
        isError={isError}
        isSuccess={isSuccess}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
    </div>
  );
}

function DrawerCompose({ setItems, endpoint }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <>
      <Button
        className="compose-button"
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
      >
        <Icon icon={ADD} />
        <div>Yeni E-mail</div>
      </Button>
      <Drawer
        className="lg"
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      >
        <MailCompose setItems={setItems} endpoint={endpoint} />
      </Drawer>
    </>
  );
}

function MailBoxSide({
  mails,
  activeTab,
  setActiveTab,
  isSendable = true,
  setItems,
  endpoint,
}) {
  const statusCounts = mails.reduce((acc, mail) => {
    acc[mail.status] = (acc[mail.status] || 0) + 1;
    if (mail.isImportant && mail.status !== "deleted")
      acc["important"] = (acc["important"] || 0) + 1;
    return acc;
  }, {});

  const handleTabClick = (event, status) => {
    setActiveTab(status);

    document.querySelectorAll(".side-bar li").forEach((li) => {
      li.classList.remove("active");
    });
    if (status !== "compose")
      event.target.closest("li").classList.add("active");
  };

  return (
    <div className="mail-box__drawer">
      <ul className="side-bar">
        {SIDE_BAR_TABS.filter((s) => {
          if (!isSendable) return s.status !== "sent";
          else return s;
        }).map((s, k) => (
          <li key={k} className={s.status === activeTab ? "active" : ""}>
            <Button color="dark" onClick={(e) => handleTabClick(e, s.status)}>
              <div className="side-bar__text">
                <Icon icon={s.icon} />
                <div>{s.text}</div>
              </div>
              {statusCounts[s.status] > 0 && (
                <div className="side-bar__count">
                  {statusCounts[s.status] > 9 ? "9+" : statusCounts[s.status]}
                </div>
              )}
            </Button>
          </li>
        ))}
      </ul>
      {isSendable && (
        <div className="compose">
          <DrawerCompose setItems={setItems} endpoint={endpoint} />
        </div>
      )}
    </div>
  );
}

const toggleStretch = (e, ref) => {
  const isActive = ref.current.classList.contains("active");

  if (isActive) {
    e.target.classList.remove("active");
    ref.current.classList.remove("active");
  } else {
    e.target.classList.add("active");
    ref.current.classList.add("active");
  }
};
function MailBoxPrevMessages({
  messages,
  activemessageHandler,
  activeMessage,
}) {
  const prevMessagesRef = useRef();

  return (
    <>
      {messages?.length > 1 && (
        <div className="mail-messsage__prev-messages-container">
          <div className="mail-message__prev-messsages ">
            <div className="d-flex justify-content-end">
              <Button onClick={(e) => toggleStretch(e, prevMessagesRef)}>
                Önceki Mesajlar
              </Button>
            </div>
            <div className="stretch mt-2" ref={prevMessagesRef}>
              <div className="stretch__content">
                {messages.map((m, k) => (
                  <MailPrevMessage
                    key={k}
                    message={m}
                    isActive={activeMessage === m}
                    onClick={() => activemessageHandler(m)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function MailBoxMessage({
  mail,
  setCurrentItem,
  isSendable,
  setItems,
  endpoint,
}) {
  const composeRef = useRef();

  const [activeMessage, setactiveMessage] = useState(
    mail?.messages?.slice(-1)[0]
  );

  const activemessageHandler = (m) => {
    setactiveMessage(m);
  };
  useEffect(() => {
    if (mail) setactiveMessage(mail?.messages.slice(-1)[0]);
    else setactiveMessage(null);
  }, [mail]);

  return (
    <div className="mail-message__container">
      {mail && activeMessage ? (
        <>
          {isSendable && (
            <MailBoxPrevMessages
              messages={mail?.messages || []}
              activeMessage={activeMessage}
              activemessageHandler={activemessageHandler}
            />
          )}
          <div className="mail-message">
            <div className="mail-message__header">
              {mail.name && (
                <div className="mail-message__from">
                  {mail.name} {mail.surname}
                </div>
              )}

              <div className="d-flex justify-content-between gap-2">
                <div>
                  <div className="mail-message__from-mail">
                    Gönderen: {mail.from}
                  </div>
                  <div className="mail-message__from-mail">
                    Alıcı: {mail.to}
                  </div>
                </div>
                <div className="mail-message__date">
                  {formatFullDate(activeMessage.date)}
                </div>
              </div>
            </div>

            <div className="mail-message__body">
              <div className="mail-message__title">{mail.subject}</div>
              <div className="mail-message__category">
                {CATEGORIES[mail.category]}
              </div>
              <div className="mail-message-content">
                {parse(activeMessage.message)}
              </div>
            </div>
            {activeMessage.files && (
              <div className="mail-message__attachments">
                <a
                  className="d-flex align-items-center justify-content-between "
                  href={`${APP_BASE_URL}uploads/${activeMessage.files.src}`}
                  download={true}
                  target="_blank"
                  rel="noreferrer"
                >
                  Ekler
                  <div>
                    {activeMessage.files.name}
                    <Icon icon={DOWNLOAD} />
                  </div>
                </a>
              </div>
            )}
            {isSendable && (
              <>
                <div className="d-flex justify-content-end">
                  <Button onClick={(e) => toggleStretch(e, composeRef)}>
                    Yanıtla
                  </Button>
                </div>
                <div className="stretch mt-2" ref={composeRef}>
                  <div className="stretch__content mail-compose mb-3">
                    <MailCompose
                      isCompose={false}
                      currentItem={mail}
                      setItems={setItems}
                      setCurrentItem={setCurrentItem}
                      endpoint={endpoint}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <small className="text-warning">Seçili e-mail bulunmuyor.</small>
      )}
    </div>
  );
}

function MailBoxContent({ items, setItems, activeTab, isSendable, endpoint }) {
  const [isAll, setIsAll] = useState(true);
  const [isNewest, setIsNewest] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const tempMails = useMemo(() => {
    let filteredMails = [
      ...items.filter((m) => {
        if (activeTab === "important")
          return m.isImportant && m.status !== "deleted";
        return m.status === activeTab;
      }),
    ];

    if (!isAll) {
      filteredMails = filteredMails.filter((mail) => mail.isRead === false);
    }

    if (searchValue) {
      const searchTerm = searchValue.toLowerCase();
      filteredMails = filteredMails.filter((mail) => {
        const titleMatches = mail.subject.toLowerCase().includes(searchTerm);

        if (mail.status === "sent") {
          const toMatches = mail.to.toLowerCase().includes(searchTerm);
          return titleMatches || toMatches;
        }

        const fromMatches = mail.from.toLowerCase().includes(searchTerm);
        return titleMatches || fromMatches;
      });
    }

    if (isNewest) {
      filteredMails.sort(
        (a, b) =>
          new Date(b.messages.slice(-1)[0].date) -
          new Date(a.messages.slice(-1)[0].date)
      );
    }

    return filteredMails;
  }, [isAll, isNewest, items, searchValue, activeTab]);

  const handleFilterButtonClick = (filterType) => {
    if (filterType === "all") {
      setIsAll(true);
    } else if (filterType === "unread") {
      setIsAll(false);
    }
  };

  const handleSortButtonClick = (sortType) => {
    if (sortType === "newest") {
      setIsNewest(true);
    } else if (sortType === "oldest") {
      setIsNewest(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };
  const onUpdate = async (property, mail) => {
    try {
      const res = await api.patch({ ...mail, property });
      setItems((prevItems) =>
        prevItems.map((item) =>
          item._id === res.data.item._id ? res.data.item : item
        )
      );
      setCurrentItem(res.data.item);
    } catch (error) {
      console.log(error);
      toast(ERROR_CODES[error.response.status], {
        type: "error",
      });
    }
  };
  const handleMailCardClick = async (mail) => {
    setCurrentItem(mail);
    if (!mail.isRead) onUpdate("read", mail);
  };

  const [currentItem, setCurrentItem] = useState(null);

  const [alertModal, setAlertModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErr, setIsDeletingErr] = useState(false);
  const [isDeletingSuccess, setIsDeletingSuccess] = useState(false);

  const api = new Api(`${endpoint}/mails`);
  const onDeleteItem = async () => {
    await deleteItem({
      id: currentItem._id,
      api,
      items,
      setItems,
      isDeleting,
      setIsDeleting,
      setIsDeletingSuccess,
      setIsDeletingErr,
      setCurrentItem,
    });
  };

  const toggleAlert = () => {
    setAlertModal(!alertModal);
  };

  return (
    <div className="mail-box__main">
      <div className="mail-box__messages">
        <InputGroup className="mb-3">
          <Input
            type="text"
            placeholder="Mail arayın..."
            value={searchValue}
            onChange={handleSearchChange}
          />
        </InputGroup>
        <div className="mail-box__filters">
          <Button
            className={isAll ? "active" : ""}
            onClick={() => handleFilterButtonClick("all")}
          >
            Tümü
          </Button>
          <Button
            className={!isAll ? "active" : ""}
            onClick={() => handleFilterButtonClick("unread")}
          >
            Okunmamış
          </Button>
        </div>

        <div className="mail-box__filters mb-4">
          <Button
            className={isNewest ? "active" : ""}
            onClick={() => handleSortButtonClick("newest")}
          >
            En Yeni
          </Button>
          <Button
            className={!isNewest ? "active" : ""}
            onClick={() => handleSortButtonClick("oldest")}
          >
            En Eski
          </Button>
        </div>
        <div className="mail-box__messages__container">
          {tempMails.length > 0 ? (
            tempMails.map((mail, key) => (
              <MailCard
                mail={mail}
                key={key}
                isActive={currentItem === mail}
                onClick={() => handleMailCardClick(mail)}
                onUpdate={(property) => {
                  setCurrentItem(mail);
                  onUpdate(property, mail);
                }}
                onDelete={toggleAlert}
              />
            ))
          ) : (
            <small className="text-warning">E-mail bulunamadı...</small>
          )}
        </div>
      </div>
      <MailBoxMessage
        mail={currentItem}
        isSendable={isSendable}
        setItems={setItems}
        setCurrentItem={setCurrentItem}
        endpoint={endpoint}
      />
      <ModalApprovemation
        modal={Boolean(alertModal)}
        toggle={toggleAlert}
        title={`Maili Sil`}
        body={`${currentItem?.subject} konulu mail silinecektir.`}
        handleSubmit={onDeleteItem}
        isSending={isDeleting}
        isError={isDeletingErr}
        isSuccess={isDeletingSuccess}
        setIsError={setIsDeletingErr}
        setIsSuccess={setIsDeletingSuccess}
      />
    </div>
  );
}

function MailBox({ items = [], setItems, isSendable = true, endpoint }) {
  const [activeTab, setActiveTab] = useState("inbox");

  return (
    <CardBody className="mail-box">
      <MailBoxSide
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        mails={items}
        isSendable={isSendable}
        setItems={setItems}
        endpoint={endpoint}
      />
      <MailBoxContent
        activeTab={activeTab}
        isSendable={isSendable}
        items={items}
        setItems={setItems}
        endpoint={endpoint}
      />
    </CardBody>
  );
}

export default MailBox;
