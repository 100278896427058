const DOWN = "icon-park-solid:down-one";
const LEFT = "uiw:left";
const RIGHT = "uiw:right";
const USERS = "teenyicons:users-solid";
const EDIT = "material-symbols:edit";
const UPLOAD = "bytesize:upload";
const ADD = "material-symbols:add";
const MINUS = "ic:baseline-minus";
const DELETE = "material-symbols:delete";
const DELETE_OUTLINE = "material-symbols:delete-outline";
const SEND = "material-symbols-light:send";
const FLAG_FILLED = "solar:flag-bold";
const FLAG_OUTLINE = "solar:flag-linear";
const DRAFT = "material-symbols:draft";
const DOWNLOAD = "material-symbols-light:download";
const INBOX = "material-symbols:inbox";
const REDO = "material-symbols:redo";
const PAGES = "uil:window";
const SHOPPING = "mdi:shopping-outline";
const ADMIN = "ri:admin-fill";
const BLOG = "fluent:reading-mode-mobile-24-filled";
const MAIL = "material-symbols:mail";
const INFO = "clarity:help-info-solid";
const FAQ = "zondicons:question";
const SHOPPING_HELP = "tabler:shopping-cart-question";
const RETURNS = "icon-park-outline:return";
const SUPPORT_CENTER = "fluent:person-support-16-filled";
const FEEDBACK = "fluent:person-feedback-16-filled";
const CAREER = "material-symbols:work";
const ABOUT = "mdi:company";
const CONTACT = "grommet-icons:contact";
const OFFER = "mdi:offer";
const SCIENCE = "material-symbols:science";
const BENEFITS = "material-symbols:group-work";
const GROUP = "lets-icons:group-add-fill";
const SETTINGS = "material-symbols:settings";
const LANGUAGE = "material-symbols:language";
const USER_ROLE = "ri:user-settings-line";
const HOME = "fluent:home-12-filled";
const LOG_OUT = "material-symbols:logout";
const LOCK = "mdi:password";
const PRODUCT_BOX = "ant-design:product-outlined";
const PRODUCT_LIST = "fluent-mdl2:product-variant";
const PRODUCT_ANALYTIC = "fluent-mdl2:product-list";
const FEATURES = "material-symbols:category-outline-rounded";
const CATEGORY = "carbon:category";
const INTERSECTION = "icon-park-solid:intersection";
const OPERATION = "ep:operation";
const SEARCH = "material-symbols:search-rounded";
const CLOSE = "material-symbols:close";
const ANALYTICS = "teenyicons:pie-chart-solid";
const USER_ANALYTIC = "ph:user-list";
const CHECK = "material-symbols:check";
const PDF = "fa6-solid:file-pdf";
const WARNING = "ci:warning";
const PACKET = "arcticons:packeta"
const CARGO = "mdi:truck-cargo-container"
const SMILE = "uil:smile"
const WORLD = "mdi:world"
const PHONE = "mdi:cellphone"

export {
  PHONE,
  WORLD,
  SMILE,
  CARGO,
  PACKET,
  WARNING,
  PDF,
  CHECK,
  DOWN,
  LEFT,
  RIGHT,
  USERS,
  EDIT,
  CLOSE,
  SEARCH,
  INTERSECTION,
  HOME,
  LOCK,
  FLAG_FILLED,
  FLAG_OUTLINE,
  DRAFT,
  DOWNLOAD,
  SEND,
  DELETE,
  INBOX,
  ADD,
  DELETE_OUTLINE,
  REDO,
  PAGES,
  ADMIN,
  BLOG,
  MAIL,
  INFO,
  FAQ,
  SHOPPING_HELP,
  SHOPPING,
  RETURNS,
  SUPPORT_CENTER,
  FEEDBACK,
  CAREER,
  ABOUT,
  CONTACT,
  OFFER,
  SCIENCE,
  BENEFITS,
  GROUP,
  SETTINGS,
  LANGUAGE,
  USER_ROLE,
  LOG_OUT,
  PRODUCT_BOX,
  FEATURES,
  CATEGORY,
  PRODUCT_LIST,
  PRODUCT_ANALYTIC,
  MINUS,
  OPERATION,
  UPLOAD,
  ANALYTICS,
  USER_ANALYTIC,
};
