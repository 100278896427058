import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Spinner from "../Spinner";

function ModalApprovemation({
  modal,
  toggle,
  title,
  handleSubmit,
  body,
  isError,
  isSuccess,
  isSending,
  setIsSuccess = () => {},
  setIsError = () => {},
}) {
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      keyboard
      size="lg"
      backdrop
      className="modal-aside"
      onClosed={() => {
        setIsError(false);
        setIsSuccess(false);
      }}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <Alert color="warning">
          <div className="alert-heading h5">Uyarı!</div>
          <p>{body}</p>
          <hr />
          <p className="mb-0 mt-2">Bu işlemi onaylıyor musunuz?</p>
        </Alert>
      </ModalBody>
      <ModalFooter className="d-flex flex-column align-items-end">
        {isSending ? (
          <Spinner className="dark" />
        ) : (
          !isSuccess && (
            <div className="d-flex gap-3">
              <Button color="success" outline onClick={handleSubmit}>
                Onaylıyorum
              </Button>
              <Button color="danger" outline onClick={toggle}>
                Vazgeç
              </Button>
            </div>
          )
        )}

        {isSuccess && (
          <div className="text-success">İşlem gerçekleştirildi.</div>
        )}
        {isError && (
          <>
            <div className="text-danger">İşlem gerçekleştirilemedi.</div>
            <div className="text-danger">{isError}</div>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default ModalApprovemation;
