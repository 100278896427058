import {
    Alert,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import Spinner from "../Spinner";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config/authentication";
const { REACT_APP_BASE_URL } = process.env;

function ModalApprovemation({
    modal,
    toggle,
    name

}) {
    const [fileExcell, setFileExcell] = useState(null);
    const [updateProducArr, setUpdateProducArr] = useState([]);

    const updatePrice = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file-xdsf', fileExcell);

        axios.post(REACT_APP_BASE_URL + 'xDdJsdEv-admin/xdsf-patch', formData, {
            headers: {
                'xsfr': config.secretKey
            }
        })
            .then((res) => {
                setUpdateProducArr(res.data.response)

            })
            .catch((err) => {
            })

    }

    const handleFileChange = (e) => {
        setFileExcell(e.target.files[0]);

    }

    const exportPriceList = (e)=>{
        e.preventDefault();
        axios.post(REACT_APP_BASE_URL + 'xDdJsdEv-admin/xdsf-export', {}, {
            headers: {
                'xsfr': config.secretKey
            }
        })
            .then((res) => {
                window.location.replace(REACT_APP_BASE_URL+res.data.response)

            })
            .catch((err) => {
            })
    }
    return (
        <Modal
            isOpen={modal}
            toggle={toggle}
            keyboard
            size="lg"
            backdrop
            className="modal-aside"

        >
            <ModalHeader toggle={toggle}>{name}</ModalHeader>
            <ModalBody>
                <Alert color="warning">
                    <div className="alert-heading h5">Uyarı!</div>
                    <p>Panel üzerinden indirilen dosyada fiyat alanı haricinde değişiklik yapmayınız!</p>


                </Alert>
                <Button className="m-2" color="warning" outline onClick={(e)=>exportPriceList(e)}>Fiyat Listesini İndir</Button>
                <div className="d-flex">
                    <input type="file" className="form-control mx-2" onChange={handleFileChange} accept=".xlsx, .xls" />
                    <Button color="success" outline onClick={(e) => updatePrice(e)}>
                        Güncelle
                    </Button>
                </div>
            {updateProducArr.length ? 
                <div className="col-12 d-flex mt-3">
                    <table className="justify-content-center table">
                        <thead>
                            <th className="text-center">
                                Ürün İsmi
                            </th>
                            <th className="text-center">
                                Güncellenen Fiyat
                            </th>
                            <th className="text-center">
                                Durum
                            </th>
                            
                           
                        </thead>
                        <tbody>
                            {updateProducArr.length>0 ? updateProducArr.map((item)=>{
                                return(
                                    <tr>
                                        <td>
                                        {item.value_name+" "+item.product_name}
                                        </td>
                                        <td>
                                        {item.price}₺
                                        </td>
                                        <td>{item.status == "Güncellendi" ? <span className="text-success">Güncellendi</span> : <span className="text-danger">Güncellenmedi</span>  }</td>
                                        </tr>
                                )
                            }): null}
                        </tbody>
                    </table>
                </div> : ""}

            </ModalBody>
            <ModalFooter className="d-flex flex-column align-items-end">

                <div className="d-flex gap-3">

                    <Button color="danger" outline onClick={toggle}>
                        Kapat
                    </Button>
                </div>



            </ModalFooter>
        </Modal>
    );
}

export default ModalApprovemation;
