import { useState } from "react";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { Placeholder, Progress } from "reactstrap";
import { bytesToMB } from "../../helpers";
import { UPLOAD } from "../../data/icons";

const DragAndDropPlacholder = () => {
  return (
    <Placeholder animation="glow" tag="div">
      <Placeholder xs={12} style={{ height: 150, width: "100%" }} />
    </Placeholder>
  );
};

const DragAndDrop = ({
  maxSize,
  maxWidth,
  maxHeight,
  fixedWidth,
  fixedHeight,
  supportedFormats,
  file,
  setFile,
  smallMessageType,
  smallMessageSize,
  smallMessageResolution,
  selectImageText,
  errorMessage,
  setErrorMessage,
  required = true,
  warnings = [],
  isLoading = false,
}) => {
  const theme = useSelector((state) => state?.theme?.theme);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    console.log(selectedFile)
    if (selectedFile) {
      if (!supportedFormats.includes(selectedFile.type)) {
        setErrorMessage(smallMessageType);
        setFile(null);
      } else if (selectedFile.size > maxSize * 1024 * 1024) {
        setErrorMessage(smallMessageSize);
        setFile(null);
      } else {
        setFile(selectedFile);
        setErrorMessage("");

        const reader = new FileReader();

        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            const progress = (e.loaded / e.total) * 100;
            setLoadingProgress(progress);
          }
        };

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            if (
              (maxHeight && img.height > maxHeight) ||
              (fixedWidth && img.width !== fixedWidth) ||
              (maxWidth && img.width > maxWidth) ||
              (fixedHeight && img.height !== fixedHeight)
            ) {
              setErrorMessage(smallMessageResolution);
              setFile(null);
            } else {
              setErrorMessage("");
            }
          };
        };

        reader.readAsDataURL(selectedFile);
      }
    } else {
      setFile(null);
      setErrorMessage("Bu alan zorunludur.");
    }
  };

  if (isLoading) return <DragAndDropPlacholder />;
  return (
    <>
      {file && file?.type?.includes("image") && (
        <div className="mb-1">
          <div className="mb-1">Seçili görsel</div>
          <img
            alt="Görsel"
            width={200}
            src={URL.createObjectURL(file)}
            className="mb-2"
          />
        </div>
      )}
      {file && loadingProgress > 0 && (
        <Progress
          color={theme === "dark" ? "primary" : "purple"}
          value={loadingProgress}
          className="mb-3"
        >
          {bytesToMB(file.size)} MB
        </Progress>
      )}
      <div className="drag-drop-container postion-relative mb-2">
        <label
          htmlFor="drag-file"
          className="w-100 p-3 py-2 text-muted d-flex flex-column justify-content-center align-items-center text-center gap-2"
        >
          <Icon
            icon={UPLOAD}
            width={72}
            height={72}
            color={theme === "dark" ? "#9ab3d5" : "#7f63f4"}
          />
          {file && file.name ? file.name : selectImageText}
        </label>
        <input
          name="file"
          required={required}
          id="drag-file"
          type="file"
          onChange={handleFileSelect}
          className="input-field"
          accept={supportedFormats}
        />
      </div>
      {warnings.map((warning, index) => (
        <small className="text-warning d-block" key={index}>
          {warning}
        </small>
      ))}
      {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}
    </>
  );
};

export default DragAndDrop;
