import { useSelector } from "react-redux";
import { VERSION } from "../../data/version";

function Footer() {
  const theme = useSelector((state) => state?.theme?.theme);
  return (
    <footer>
      <div className="d-flex justify-content-center flex-column align-items-center gap-1 mb-4">
        <div>made by</div>
        <a
          href="https://osicrew.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={`/assets/logos/osi_logo_${
              theme === "dark" ? "white" : "black"
            }.webp`}
            alt="OSİ logo"
            style={{ maxWidth: 72 }}
          />
        </a>
        <small className="mt-1">{VERSION}</small>
      </div>
    </footer>
  );
}

export default Footer;
