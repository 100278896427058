function getDisplayedData(currentPage, filteredData, itemsPerPage = 3) {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayedData =
    filteredData.length > 0 ? filteredData.slice(startIndex, endIndex) : [];

  return displayedData;
}
export default getDisplayedData;
