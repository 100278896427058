/* eslint-disable no-undef */
import axios from "axios";
import Cookies from "js-cookie";
import { APP_BASE_URL } from "../data/env";
import config from "../config/authentication";

export default class Api {
  constructor(endpoint = "", contentType = "application/json") {
    this.baseURL = APP_BASE_URL + endpoint;
    const token = Cookies.get(process.env.REACT_APP_TOKEN_LOCALHOST);
    this.axiosObject = axios.create({
      baseURL: this.baseURL,
      headers: {
        "Content-Type": contentType,
        'xsfr': config.secretKey,
        'Authorization': `Bearer ${token}`

      }
    });
  }

  get(params = {}) {
    return this.axiosObject.get("/", { params });
  }

  post(body) {
    return this.axiosObject.post("", body);
  }

  patch(body) {
    return this.axiosObject.patch("/", body);
  }

  delete(params) {
    return this.axiosObject.delete("/", { params });
  }
}
