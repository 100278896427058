/* eslint-disable no-constant-condition */
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import Header from "./Header";
import Footer from "./Footer";
import Aside from "./Aside";
import useAuth from "../hooks/useAuth";
import { Loader } from "../components";

function Layout(props) {
  const { children } = props;
  const { isLoading } = useAuth();
  const theme = useSelector((state) => state?.theme?.theme);

  return (
    <Fragment>
      <Header />
      <Aside />
      {children}
      {/* <>{isLoading ? <Loader /> : children}</> */}
      <Footer />
      <ToastContainer
        position="bottom-right"
        theme={theme}
        pauseOnFocusLoss={false}
      />
    </Fragment>
  );
}

export default Layout;
