import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import useLogout from "../../hooks/useLogout";
import { NavbarItem, ThemeSwitcher, Accordion } from "../../components";
import { Icon } from "@iconify/react";
import {
  CARGO,
  ADMIN,
  ANALYTICS,
  BENEFITS,
  BLOG,
  CAREER,
  CATEGORY,
  CONTACT,
  FAQ,
  FEATURES,
  FEEDBACK,
  GROUP,
  HOME,
  INFO,
  INTERSECTION,
  LANGUAGE,
  LOG_OUT,
  MAIL,
  OFFER,
  PAGES,
  PRODUCT_ANALYTIC,
  PRODUCT_BOX,
  PRODUCT_LIST,
  RETURNS,
  SCIENCE,
  SETTINGS,
  SHOPPING,
  SUPPORT_CENTER,
  USERS,
  USER_ANALYTIC,
} from "../../data/icons";

function Aside() {
  const asideRef = useRef(null);
  const logout = useLogout();

  // const permissions = useSelector((state) => state.auth.user?.permissions);

  useEffect(() => {
    function handleClickOutside(event) {
      if (asideRef.current && !asideRef.current.contains(event.target)) {
        asideRef.current.classList.remove("active");
        document.body.style.overflow = "auto";
        window.removeEventListener("click", handleClickOutside);
      }
    }

    function handleToggleClick() {
      asideRef.current.classList.toggle("active");
      if (asideRef.current.classList.contains("active")) {
        document.body.style.overflow = "hidden";
        window.addEventListener("click", handleClickOutside);
      } else {
        document.body.style.overflow = "auto";
        window.removeEventListener("click", handleClickOutside);
      }
    }

    const button = asideRef.current.querySelector(".menu");
    button.addEventListener("click", handleToggleClick);

    return () => {
      document.body.style.overflow = "auto";
      window.removeEventListener("click", handleClickOutside);
      button.removeEventListener("click", handleToggleClick);
    };
  }, []);

  return (
    <aside ref={asideRef}>
      <Button color="button" className="menu" tabIndex={1}>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
      </Button>

      <nav>
        <NavbarItem title="Anasayfa" icon={HOME} to="home" />


        <Accordion
          accordionHeader={
            <div className="d-flex flex-nowrap align-items-center text-nowrap">
              <Icon icon={PRODUCT_BOX} className="aside__icon" /> Ürünler
            </div>
          }
          accordionBody={
            <>
              <NavbarItem
                title="Tüm Ürünler"
                icon={PRODUCT_LIST}
                to="products/product-list"
              />
              <NavbarItem
                title="Kategoriler"
                icon={CATEGORY}
                to="products/categories"
              />
              {/* <NavbarItem
                  title="Kombinasyonlar"
                  icon={INTERSECTION}
                  to="products/combinations"
                /> */}
              <NavbarItem
                title="Özellikler"
                icon={FEATURES}
                to="products/attributes"
              />
            </>
          }
        />
        <NavbarItem title="Kuponlar" icon={PRODUCT_ANALYTIC} to="coupon" />
        <NavbarItem title="Kampanyalar" icon={FEATURES} to="campaing" />

        <NavbarItem title="Kargo" icon={CARGO} to="cargo-price" />
        <NavbarItem title="Siparişler" icon={SHOPPING} to="orders" />

        <NavbarItem title="Müşteriler" icon={USERS} to="customers" />

      </nav>
      <div className="d-flex align-items-center justify-content-between w-100 overflow-hidden gap-4">
        <div onClick={logout} className="flex-grow-1 logout">
          <NavbarItem title="Çıkış Yap" icon={LOG_OUT} />
        </div>
        <ThemeSwitcher />
      </div>
    </aside>
  );
}

export default Aside;
