import CryptoJS from "crypto-js";

const encryptText = (object) => {
  const jsonString = JSON.stringify(object);
  const encrypted = CryptoJS.AES.encrypt(
    jsonString,
    process.env.REACT_APP_CRYPTO_KEY
  ).toString();
  return encrypted;
};

const decryptText = (encryptedText) => {
  try {
    const decrypted = CryptoJS.AES.decrypt(
      encryptedText,
      process.env.REACT_APP_CRYPTO_KEY
    ).toString(CryptoJS.enc.Utf8);

    if (decrypted.trim() === "") {
      throw new Error("Decrypted text is empty");
    }

    const parsedData = JSON.parse(decrypted);

    return parsedData;
  } catch (error) {
    console.error("Error during decryption or parsing:", error);

    return false;
  }
};

export { encryptText, decryptText };
