import { Icon } from "@iconify/react";
import { Button } from "reactstrap";
import { formatDate } from "../../helpers";
import {
  DELETE,
  DELETE_OUTLINE,
  FLAG_FILLED,
  FLAG_OUTLINE,
  REDO,
} from "../../data/icons";

function MailCard(props) {
  const { mail, isActive = false, onClick, onDelete, onUpdate } = props;

  const { status } = mail;
  return (
    <div className={`mail-card ${isActive && " active"}`} onClick={onClick}>
      <div className="mail-card__header">
        <div className="mail-card__title">{mail.subject}</div>
        {!mail.isRead && status !== "sent" && (
          <div className="mail-card__unread"></div>
        )}
      </div>
      <div className="mail-card__body">
        <div className="mail-card__from">
          {status === "sent" ? mail.to : mail.from}
        </div>
        <div className="mail-card__date">
          {formatDate(mail.messages.slice(-1)[0].date)}
        </div>
      </div>
      <div className="d-flex gap-2 mt-2">
        <Button size="sm" onClick={() => onUpdate("important")}>
          <Icon icon={mail.isImportant ? FLAG_FILLED : FLAG_OUTLINE} />
        </Button>

        {status === "deleted" ? (
          <>
            <Button size="sm" onClick={onDelete}>
              <Icon icon={DELETE} />
            </Button>
            <Button size="sm" onClick={() => onUpdate("delete")}>
              <Icon icon={REDO} />
            </Button>
          </>
        ) : (
          <Button size="sm" onClick={() => onUpdate("delete")}>
            <Icon icon={DELETE_OUTLINE} />
          </Button>
        )}
      </div>
    </div>
  );
}

export default MailCard;
