import { Icon } from "@iconify/react";
import { NavLink } from "react-router-dom";

function NavBarItem(props) {
  const { title, to, icon } = props;
  return (
    <NavLink
      to={`${to ? `/${to}` : "/"}`}
      className={({ isActive }) => (isActive ? "active" : "")}
    >
      <div className="nav-item">
        <Icon icon={icon} className="nav-item-img" />
        <div className="nav-item-title">{title}</div>
      </div>
    </NavLink>
  );
}

export default NavBarItem;
