import { Icon } from "@iconify/react";
import { DOWN } from "../../data/icons";
import "./style.css";
import { Button } from "reactstrap";
import { useRef } from "react";

function Accordion({
  className = "",
  accordionHeader,
  accordionBody,
  styleBody = {},
}) {
  const accordionRef = useRef();

  const toggleAccordion = () => {
    const currentClassList = accordionRef.current.classList;

    if (currentClassList.contains("active")) {
      currentClassList.remove("active");
      const accordionElements =
        accordionRef.current.getElementsByClassName("accordion");

      for (let i = 0; i < accordionElements.length; i++) {
        const accordionElement = accordionElements[i];
        const accordionClassList = accordionElement.classList;

        accordionClassList.remove("active");
      }
    } else {
      currentClassList.add("active");
    }
  };

  return (
    <div ref={accordionRef} className={`accordion ${className}`}>
      <Button className="accordion__header" onClick={toggleAccordion}>
        {accordionHeader}
        {accordionBody && <Icon icon={DOWN} className="accordion__icon" />}
      </Button>
      {accordionBody && (
        <div className="accordion__body">
          <div className="accordion__body__content" style={styleBody}>
            {accordionBody}
          </div>
        </div>
      )}
    </div>
  );
}

export default Accordion;
