import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Card,
    CardBody,
    Container,
    Row,
  } from "reactstrap";

const Google = () => {
  const [pages, setPages] = useState([]);
  const [devices, setDevices] = useState([]);
  const [activeUsers, setActiveUsers] = useState(0);
  const [countries, setCountries] = useState([]);

  const getDeviceColor = (device) => {
    switch (device) {
      case 'mobile':
        return 'warning';
      case 'desktop':
        return 'success';
      case 'tablet':
        return 'danger';
      default:
        return '';
    }
  };

  const getData = async () => {
    try {
      const res = await axios.get('/data.php');
      const data = res.data;

      setPages(data.title || []);
      setDevices(data.device || []);
      setActiveUsers(data.device ? data.device.length : 0);
      setCountries(data.country || []);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    getData();
    const intervalId = setInterval(() => {
      getData();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <main>
      <Container>
        <div className="h1">Raporlar</div>
      </Container>
      <section>
        <Container>
          <Row>
            <Card>
              <CardBody>
                <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
                  <div className="container page-content">
                    <div className="row">
                      <div className="col-md-8">
                        <table className="table table-bordered" id="result-pages">
                          <thead>
                            <tr>
                              <th width="40%">Top Active Pages</th>
                              <th>Views</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pages.length > 0 ? pages.map((page, index) => (
                              <tr key={index} className="open-link" data-link="https://google.com">
                                <td>{page.unifiedScreenName}</td>
                                <td>{page.active_users}</td>
                              </tr>
                            )) : <tr><td colSpan="2">No data available</td></tr>}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-4">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <th>Right Now</th>
                            </tr>
                            <tr>
                              <td>
                                <h1>
                                  <div className="count" id="active-users">
                                    {activeUsers}
                                  </div>
                                </h1>
                                <p>Active Users on Site</p>
                                <br />
                                <div>
                                  <div className="progress-label" style={{ display: 'flex' }}>
                                    <div className={`label label-warning`}>
                                      <span>Mobile</span>
                                    </div>
                                    <div className={`label label-success`}>
                                      <span>Desktop</span>
                                    </div>
                                    <div className={`label label-danger`}>
                                      <span>Tablet</span>
                                    </div>
                                  </div>
                                  <div id="devices">
                                    <div className="progress" style={{ width: '100%' }}>
                                      {devices.length > 0 ? devices.map((device, index) => {
                                        const percent = (device.active_users / activeUsers) * 100;
                                        return (
                                          <div
                                            key={index}
                                            className={`progress-bar progress-bar-${getDeviceColor(device.deviceCategory)}`}
                                            style={{ width: `${percent}%` }}
                                          >
                                            {device.active_users}
                                          </div>
                                        );
                                      }) : <div>No data available</div>}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table className="table table-bordered" id="countries-sources">
                          <thead>
                            <tr>
                              <th>Country</th>
                              <th>Users</th>
                            </tr>
                          </thead>
                          <tbody>
                            {countries.length > 0 ? countries.map((country, index) => (
                              <tr key={index}>
                                <td>{country.country}</td>
                                <td>{country.active_users}</td>
                              </tr>
                            )) : <tr><td colSpan="2">No data available</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Google;
