import Profile from "../../components/Profile";

function Header() {
  return (
    <header>
      <div
        className="nav-box d-none d-xl-grid"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        {/* <svg
          id="logo"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 74 55"
          className="company-logo"
        >
          <path
            id="Path_5"
            data-name="Path 5"
            d="M73.088,29.229,62.135,10.418,58,3.321A6.83,6.83,0,0,0,53.031,0H20.969l-.083.015a7.316,7.316,0,0,0-3.91,2.013A6.528,6.528,0,0,0,16,3.321l-4.133,7.1L.912,29.229a6.724,6.724,0,0,0-.875,4.09c0,.014,0,.027.006.041A6.707,6.707,0,0,0,.912,36l1.977,3.4L11.8,55h6.992L15.311,49.02l-.073-.126L9.71,39.4,7.449,35.515a5.515,5.515,0,0,1-.711-2.156c0-.014,0-.027-.006-.041a5.5,5.5,0,0,1,.717-3.35l11.384-19.55L19.8,8.752a5.6,5.6,0,0,1,4.844-2.773H49.354A5.6,5.6,0,0,1,54.2,8.752l.969,1.666,11.385,19.55a5.507,5.507,0,0,1,.716,3.35c0,.014,0,.027-.006.041a5.5,5.5,0,0,1-.71,2.156L64.291,39.4l-5.529,9.5-.073.126L55.206,55h7.018L73.088,36a6.737,6.737,0,0,0,.869-2.644l.006-.041a6.73,6.73,0,0,0-.875-4.09"
            transform="translate(0)"
          />
          <path
            id="Path_6"
            data-name="Path 6"
            d="M61.017,35.152a2.3,2.3,0,0,0-1.249-.792H36.334a3.042,3.042,0,0,0,.6,5.945l.024,0h0l.016,0,15.525.006-4.523,7.764a3.4,3.4,0,0,1-2.93,1.678H30.074a3.394,3.394,0,0,1-2.93-1.678l-4.53-7.775L19.66,35.237a3.269,3.269,0,0,1-.346-.877c-.006-.022-.012-.044-.018-.066s-.007-.027-.01-.041a3.123,3.123,0,0,1,.374-2.322q1.44-2.632,2.99-5.321,2.241-3.887,4.494-7.523a3.392,3.392,0,0,1,2.934-1.678H45.044a3.4,3.4,0,0,1,2.471,1.062l0,0c2.957,4.982,5.126,8.616,5.471,9.165a1.594,1.594,0,0,0,.282.34,2.066,2.066,0,0,0,1.268.49,3.742,3.742,0,0,0,2.683-.763,2.644,2.644,0,0,0,1.051-3.367c-3.151-5.322-5.48-9.245-6.326-10.7a4.585,4.585,0,0,0-.647-.858,4.663,4.663,0,0,0-1.729-1.145l-.119-.042a2.625,2.625,0,0,0-.846-.141H26.634a2.608,2.608,0,0,0-.688.093c-.023.006-.047.012-.07.02-.039.011-.077.025-.115.038-.062.022-.122.049-.183.073a4.635,4.635,0,0,0-2.286,1.963Q18.146,22.442,13,31.247a4.59,4.59,0,0,0-.623,2.337,4.5,4.5,0,0,0,.046.668c0,.014.006.028.008.041s.009.044.013.066A4.544,4.544,0,0,0,13,35.921c1.777,3.054,2.559,4.38,2.559,4.38.817,1.281,1.466,2.346,1.915,3.093.649,1.083,1.2,2.029,2.084,3.57.82,1.421,1.026,1.808,1.624,2.8.258.428.648,1.076,1.124,1.869.9,1.509.968,1.626,1.064,1.769a5.924,5.924,0,0,0,3.654,2.212l.029.008a2.86,2.86,0,0,0,.7.089H47.477a2.709,2.709,0,0,0,1.062-.283l.082-.045a12.541,12.541,0,0,0,5.153-5.345c.054-.106.1-.17.163-.28l.027-.046c1.558-2.7,2.922-4.938,2.922-4.938,1-1.628,1.858-2.977,2.8-4.526.325-.534.262-.438.424-.7a13.191,13.191,0,0,0,1.119-1.887,2.678,2.678,0,0,0,.287-1.1,2.329,2.329,0,0,0-.5-1.407"
            transform="translate(-0.798 -0.713)"
          />
        </svg> */}
        <div className="osi-panel">
          <b>osi</b>&nbsp;panel
        </div>
      </div>
      <Profile />
    </header>
  );
}

export default Header;
