import { Button } from "reactstrap";
import Spinner from "../Spinner";
import { memo } from "react";

const SaveCancelButton = memo(
  ({
    isSending,
    isSuccess,
    isError,
    onSave,
    onCancel,
    className = "d-flex flex-column align-items-end",
    successText = "Kaydet",
    saveType = "button",
  }) => (
    <div className={className}>
      {isSending ? (
        <Spinner className="dark" />
      ) : (
        !isSuccess && (
          <div className="d-flex gap-3">
            <Button type={saveType} color="success" onClick={onSave}>
              {successText}
            </Button>
            {onCancel && (
              <Button color="danger" onClick={onCancel}>
                Vazgeç
              </Button>
            )}
          </div>
        )
      )}
      {isError && (
        <div className="text-danger mt-2">
          {isError || "İşlem gerçekleştirilemedi."}
        </div>
      )}
      {isSuccess && <div className="text-success">İşlem gerçekleştirildi.</div>}
    </div>
  )
);
SaveCancelButton.displayName = "SaveCancelButton";
export default SaveCancelButton;
