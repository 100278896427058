function getNestedProperty(obj, property) {
  const nestedProperties = property.split("."); // Split nested properties by dot notation
  return nestedProperties.reduce((acc, prop) => acc && acc[prop], obj);
}

export default function filterData(data, filterText, properties = ["title"]) {
  return data.filter((item) => {
    return properties.some((property) => {
      const value = getNestedProperty(item, property);

      if (value && typeof value === "string") {
        return value.toLowerCase().includes(filterText.toLowerCase());
      } else {
        return false;
      }
    });
  });
}
