import { Icon } from "@iconify/react";
import { memo, useEffect } from "react";
import { Container } from "reactstrap";
import { OPERATION } from "../../data/icons";

function DrawerComponent({
  className,
  isDrawerOpen,
  setIsDrawerOpen,
  children,
  drawerHeader = (
    <>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div>
          <Icon icon={OPERATION} width={24} className="me-2" />
          İşlemler
        </div>
        <Icon
          icon="ic:round-close"
          width={24}
          cursor="pointer"
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        />
      </div>
      <hr />
      <br />
    </>
  ),
}) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isDrawerOpen) {
        const drawer = document.querySelector(".drawer");
        if (drawer && !drawer.contains(event.target)) {
          const modal = document.querySelector(".modal");
          if (!modal || !modal.contains(event.target)) {
            setIsDrawerOpen(false);
            document.body.style.overflow = "auto";
          }
        }
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape" && isDrawerOpen) {
        setIsDrawerOpen(false);
        document.body.style.overflow = "auto";
      }
    };

    if (isDrawerOpen) {
      document.body.style.overflow = "hidden";
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleKeyDown);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isDrawerOpen, setIsDrawerOpen]);

  return (
    <div
      className={`drawer ${className ? className : ""} ${
        isDrawerOpen ? "open" : ""
      }`}
    >
      <Container key={isDrawerOpen}>
        {drawerHeader}
        {children}
      </Container>
    </div>
  );
}

const Drawer = memo(DrawerComponent);

export default Drawer;
