import { useCallback, memo, useRef } from "react";
import { FormFeedback, FormGroup, FormText, Input, Label } from "reactstrap";
import InputMask from "react-input-mask";
import ReactQuill from "react-quill";

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: "" }, { align: "center" }, { align: "right" }],
    ["link", "image"],
    ["code-block"],
  ],
};

const InputField = memo(
  ({
    id,
    validationSchema,
    value,
    setValue,
    error,
    setError,
    args,
    label,
    mask = "",
    formText,
    className = "",
    placeholder = "",
    type = "",
    rows = 5,
    options = [],
    disabled = false,
  }) => {
    const validateField = async (fieldName, value) => {
      try {
        await validationSchema.validateAt(fieldName, { [fieldName]: value });
        setError("");
      } catch (error) {
        setError(error.message);
      }
    };

    const quillRef = useRef(null);
    const handleChange = useCallback(
      (event) => {
        if (event.target) {
          const { value } = event.target;
          setValue(value);
          validateField(id, value);
        } else {
          // QUILL
          console.log(event);
          setValue(event);
          validateField(id, event === "<p><br></p>" ? "" : event);
        }
      },
      [validateField]
    );

    const isInvalid = Boolean(error);
    const isValid = !isInvalid  && value > 0 && value !== "<p><br></p>";

    return (
      <FormGroup className={className}>
        {label && <Label htmlFor={id}>{label}</Label>}
        {mask ? (
          <InputMask mask={mask} value={value} onChange={handleChange}>
            {(inputProps) => (
              <Input
                id={id}
                name={id}
                {...inputProps}
                {...args}
                invalid={isInvalid}
                valid={isValid}
                value={value}
                type="text"
                placeholder={placeholder}
                onChange={handleChange}
              />
            )}
          </InputMask>
        ) : type === "text-editor" ? (
          <ReactQuill
            ref={quillRef}
            id={id}
            className={`form-control ${isValid ? "is-valid" : ""} ${
              isInvalid ? "is-invalid" : ""
            }`}
            {...args}
            value={value}
            onChange={handleChange}
            modules={modules}
            placeholder={placeholder}
          />
        ) : type === "select" ? (
          <Input
            id={id}
            name={id}
            type="select"
            value={value}
            onChange={handleChange}
            invalid={isInvalid}
            valid={isValid}
            defaultChecked={options.find}
            {...args}
          >
            {options.map((o, i) => (
              <option key={i} value={o.value} defaultValue={value}>
                {o.text}
              </option>
            ))}
          </Input>
        ) : (
          <Input
            id={id}
            name={id}
            {...args}
            invalid={isInvalid}
            valid={isValid}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            type={type}
            rows={rows}
            disabled={disabled}
          />
        )}
        {formText && <FormText>{formText}</FormText>}
        {error && <FormFeedback invalid>{error}</FormFeedback>}
      </FormGroup>
    );
  }
);
InputField.displayName = "InputField";
export default InputField;
