import Spinner from "../Spinner";

function Loader() {
  return (
    <div
      className="loader d-flex align-items-center justify-content-center"
      style={{
        position: "fixed",
        height: "100vh",
        left: 0,
        top: 0,
        width: "100%",
        zIndex: 5,
      }}
    >
      <Spinner className="large" />
    </div>
  );
}

export default Loader;
