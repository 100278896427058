// Table
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import {
  Button,
  Card,
  CardBody,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Table as ReactTable,
} from "reactstrap";
import { filterData, getDisplayedData } from "../../helpers";
import { ADD, SEARCH } from "../../data/icons";
import { Paginator, Spinner } from "../../components";

function Table({
  items,
  setCurrentItem,
  toggle,
  CustomRow,
  newText,
  headings = [],
  properties = [],
  isLoading = false,
  isError = false,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    const filtered = filterData(items, inputText, properties);
    setFilteredData(filtered);
    setCurrentPage(1);
  };

  useEffect(() => {
    setFilteredData(items);
    setCurrentPage(1);
  }, [items]);

  const displayedData = getDisplayedData(currentPage, filteredData, 15);

  return (
    <section>
      <Container>
        <Row>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
                <InputGroup className="outlined col-4">
                  
                </InputGroup>
                <Button
                  color="success"
                  className="text-nowrap"
                  onClick={() => {
                    setCurrentItem(null);
                    toggle();
                  }}
                >
                  <Icon icon={ADD} width={20} />
                  &nbsp;{newText}
                </Button>
              </div>
              <br />
              <ReactTable
                hover
                borderless
                responsive
                className="custom-table-bordered-head"
              >
                <thead>
                  <tr>
                    {headings.map((h, i) => (
                      <th style={{ minWidth: 150 }} key={i + 1}>
                        {h}
                      </th>
                    ))}
                  </tr>
                </thead>
                {isLoading ? (
                  <Spinner className="mt-2" />
                ) : isError ? (
                  <div>Bir hata meydana geldi</div>
                ) : filteredData.length > 0 ? (
                  <tbody>
                    {displayedData.map((item) => (
                      <CustomRow key={item._id} item={item} />
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="text-warning" colSpan={headings.length}>
                        Bulunamadı...
                      </td>
                    </tr>
                  </tbody>
                )}
              </ReactTable>
              <Paginator
                currentPage={currentPage - 1}
                activeClassName="active border px-3 py-2 rounded"
                setCurrentPage={setCurrentPage}
                pageCount={Math.ceil(filteredData.length / 15)}
              />
            </CardBody>
          </Card>
        </Row>
      </Container>
    </section>
  );
}

export default Table;
