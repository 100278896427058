import { useState } from "react";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Popover from "../PopUp";
import ResetPasswordForm from "./ResetPasswordForm";
import useLogout from "../../hooks/useLogout";
import { LOCK, LOG_OUT } from "../../data/icons";

function Profile() {
  const logout = useLogout();

  const [modal, setModal] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  const { user } = useSelector((state) => state.auth);
  return (
    <>
      <div className={`profile ${popoverOpen ? "active" : ""}`}>
        <Button
          color="link"
          className="profile-container p-0 rounded-circle"
          id="profile"
          onClick={toggle}
          tabIndex={7}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 50 50"
          >
            <defs>
              <linearGradient
                id="linear-gradient"
                y1={1}
                x2={1}
                gradientUnits="objectBoundingBox"
              >
                <stop offset={0} stopColor="#40826d" />
                <stop offset={1} stopColor="#7fda69" />
              </linearGradient>
            </defs>
            <circle id="profile_bg" cx={25} cy={25} r={25} fill="#efefef" />
            <circle id="profile_hover_bg" cx={25} cy={25} r={25} opacity={0} />
            <path
              id="user"
              d="M15.97,16.411a7.206,7.206,0,1,1,7.206-7.206A7.206,7.206,0,0,1,15.97,16.411ZM3,27.94v1.441a1.441,1.441,0,0,0,1.441,1.441H27.5a1.441,1.441,0,0,0,1.441-1.441V27.94a5.764,5.764,0,0,0-5.764-5.764H8.764A5.764,5.764,0,0,0,3,27.94Z"
              transform="translate(9.03 8.589)"
              fill="#1d1d1d"
            />
          </svg>
        </Button>
        <Popover
          className="pop-up"
          target="profile"
          toggle={toggle}
          isOpen={popoverOpen}
          placement="bottom-end"
          setPopoverOpen={setPopoverOpen}
          popoverOpen={popoverOpen}
        >
          <div className="font-bold text-truncate px-3">
            {user?.name} {user?.lastname}
          </div>
          <div className="font-light text-truncate px-3 mb-2">
            {user?.email}
          </div>
          <hr />
          {/* <div
            className="d-flex align-items-center justify-content-start pop-up-item py-2 px-3"
            onClick={() => {
              toggle();
              setModal(true);
            }}
          >
            <div className="nav-item-img-wrapper">
              <Icon icon={LOCK} width={24} height={24} />
            </div>
            <div className="nav-item-title">Şifre Değiştir</div>
          </div> */}
          <hr />
          <div
            className="d-flex align-items-center justify-content-star pop-up-item py-2 px-3"
            onClick={logout}
          >
            <div className="nav-item-img-wrapper">
              <Icon icon={LOG_OUT} width={24} height={24} />
            </div>
            <div className="nav-item-title">Çıkış Yap</div>
          </div>
        </Popover>
      </div>
      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        backdrop
        keyboard
        size="xl"
      >
        <ModalHeader>Şifre Yenileme</ModalHeader>
        <ModalBody>
          <ResetPasswordForm />
        </ModalBody>
      </Modal>
    </>
  );
}

export default Profile;
