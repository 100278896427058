import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
  const navigate = useNavigate();

  const logout = () => {
    Cookies.remove(process.env.REACT_APP_TOKEN_LOCALHOST);
    Cookies.remove(process.env.REACT_APP_USER_KEY);
    navigate("/");
  };

  return logout;
};

export default useLogout;
