import ReactPaginate from "react-paginate";
import { Button } from "reactstrap";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";

function Paginator({
  previousLabel = "Önceki",
  nextLabel = "Sonraki",
  pageCount,
  containerClassName = "pagination align-items-center gap-3 w-100 mt-3",
  activeClassName = "active border px-3 py-2 rounded border-light",
  buttonClassName = "d-flex align-items-center gap-2 rounded py-2 px-3",
  breakLabel = "...",
  setCurrentPage,
  iconPrev = "fluent:previous-frame-20-filled",
  iconNext = "fluent:next-frame-20-filled",
  isOutlined = true,
  currentPage,
}) {
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const theme = useSelector((state) => state?.theme?.theme);
  return (
    <ReactPaginate
      forcePage={currentPage}
      previousLabel={
        <Button
          color={theme === "light" ? "dark" : "light"}
          outline={isOutlined}
          size="sm"
          className={buttonClassName}
        >
          <Icon icon={iconPrev} width={20} />
          <div className="d-none d-md-block">{previousLabel}</div>
        </Button>
      }
      nextLabel={
        <Button
          color={theme === "light" ? "dark" : "light"}
          outline={isOutlined}
          size="sm"
          className={buttonClassName}
        >
          <div className="d-none d-md-block">{nextLabel}</div>
          <Icon icon={iconNext} width={20} />
        </Button>
      }
      breakLabel={breakLabel}
      breakClassName={"break-me"}
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      onPageChange={handlePageChange}
      containerClassName={containerClassName}
      activeClassName={`${activeClassName} border-${
        theme === "light" ? "dark" : "light"
      }`}
    />
  );
}

export default Paginator;
